import React from "react"

import { ReactComponent as TwentyTwentyTwo } from "../images/svgs/2022.svg"
import { ReactComponent as Dots } from "../images/svgs/dots.svg"
import { ReactComponent as Waves } from "../images/svgs/waves.svg"

const AllSvgs = {
  twentyTwentyTwo: TwentyTwentyTwo,
  dots: Dots,
  waves: Waves,
}

const getIconMarkup = svg => {
  return React.createElement(AllSvgs[svg])
}

function Svg({ svg, variation, className = "", ariaLabel, onClick }) {
  const kebabCaseName = svg.replace(
    /[A-Z]/g,
    letter => `-${letter.toLowerCase()}`
  )

  const standardStyle = `
    Svg Svg--${kebabCaseName} ${className}
    ${!!variation ? `Svg--${kebabCaseName}--${variation}` : ""}
  `
  return (
    <div className={standardStyle} aria-label={ariaLabel} onClick={onClick}>
      {getIconMarkup(svg)}
    </div>
  )
}

export default Svg
