import * as React from "react"

import Svg from "../components/svg"
import Seo from "../components/seo"

import Logo from "../images/pngs/hxouse-adidas.png"
import CPMP from "../images/pngs/cpmp.png"

const IndexPage = () => {
  return (
    <>
      <Seo />
      <main className="font-pp flex items-center justify-center underline-offset-4">
        <div className="middle:h-screen max-w-[1725px] p-4 py-12 middle:p-8 middle:py-8 gap-y-12 middle:gap-y-0 gap-x-12 grid grid-cols-1 middle:grid-cols-5 middle:grid-rows-5">
          <img
            className="col-span-full justify-self-center w-1/3 middle:w-1/5"
            src={Logo}
            alt="Logo"
          />

          <div className="middle:self-start row-span-2 uppercase text-center flex flex-col items-center gap-4 middle:gap-8 text-sm xl:text-base">
            <div className="flex gap-x-2 whitespace-nowrap">
              <p className="underline">HXOUSE</p>
              <p>X</p>
              <p className="underline">Adidas Studio</p>
            </div>
            <div className="flex gap-x-2 underline">
              <p>Creative</p>
              <p>Project</p>
              <p className="block middle:hidden">Management</p>
              <p className="block middle:hidden">Program</p>
            </div>
            <div className="hidden middle:flex gap-x-2 underline">
              <p>Management</p>
              <p>Program</p>
            </div>
          </div>

          <img
            className="self-center w-full row-span-3 middle:col-span-3 h-full"
            src={CPMP}
            alt="CPMP Logo"
          />

          <div className="middle:self-start flex flex-col gap-6 items-center underline text-sm xl:text-base">
            <div className="flex gap-2">
              <p>THE</p>
              <p>EVOLUTION</p>
              <p className="block middle:hidden">OF</p>
            </div>
            <div className="hidden middle:flex gap-2">
              <p>OF</p>
              <p>THE</p>
            </div>
            <div className="flex gap-2">
              <p className="block middle:hidden">THE</p>
              <p>CREATIVE</p>
            </div>
          </div>

          <div className="self-start text-center text-xs larger:text-[0.813rem] evenLarger:text-sm">
            <p className="mb-4">
              The HXOUSE x adidas Creative Project Management Program; a set of
              workshops focused on giving emerging creatives the skill set to
              create multifaceted experiences that brands are looking to
              deliver.
            </p>
            <p>
              Tenants of the HXOUSE x adidas Studio will gain access to
              knowledge and insights about building these experiences from
              ideation to production. In collaboration with the HXOUSE and
              adidas team, Tenants will have access to industry leaders and be
              given the opportunity to collaborate with HXOUSE and adidas on a
              series of activations.
            </p>
          </div>

          <div className="items-center self-end hidden middle:flex gap-12 w-full middle:w-auto xl:w-full">
            <Svg svg="dots" className="w-full" ariaLabel="Dots" />
            <Svg svg="twentyTwentyTwo" className="w-full" ariaLabel="2022" />
            <Svg svg="waves" className="w-full" ariaLabel="Waves" />
          </div>

          <div className="items-center justify-self-center flex middle:hidden gap-12 w-1/2">
            <Svg svg="dots" className="text-grey w-full" ariaLabel="Dots" />
            <Svg
              svg="twentyTwentyTwo"
              className="text-grey w-full"
              ariaLabel="2022"
            />
            <Svg svg="waves" className="text-grey w-full" ariaLabel="Waves" />
          </div>

          <a
            className="self-end flex justify-center gap-2 underline text-sm xl:text-base"
            href="https://hxouse.slideroom.com/#/permalink/program/69363 "
            target="_blank"
            rel="noreferrer noopener"
          >
            <p>CLICK</p>
            <p>TO</p>
            <p>APPLY</p>
          </a>

          <p className="hidden middle:block text-center col-span-full justify-self-center self-end text-xs middle:text-sm">
            © 2022 HXOUSE Special Programming. All Rights Reserved.
          </p>

          <div className="block middle:hidden">
            <p className="text-center col-span-full justify-self-center self-end text-xs">
              © 2022 HXOUSE Special Programming.
            </p>
            <p className="text-center col-span-full justify-self-center self-end text-xs">
              All Rights Reserved.
            </p>
          </div>
        </div>
      </main>
    </>
  )
}

export default IndexPage
